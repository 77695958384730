import { CombinedNode } from '../../api/types'
import React, { useMemo, useRef, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Table } from '../Table'
import { ConfirmationModal } from '../ConfirmationModal'
import { api } from '../../api/api'
import { TopUpModal } from './TopUpModal'
import { toasts } from '../../common/toast'
import { ConsumerConnectModal } from './ConsumerConnectModal'
import { useNavigate } from 'react-router'

type Props = {
  nodes: CombinedNode[]
}
export const NodesTable = ({ nodes }: Props) => {
  const navigate = useNavigate()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showTopupModal, setShowTopupModal] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const nodeRef = useRef<CombinedNode>()

  const deleteNode = async (combined: CombinedNode) =>
    combined.mode === 'consumer'
      ? api.deleteConsumer(combined.node.containerName)
      : api.deleteProvider(combined.node.containerName)

  const columns = useMemo<ColumnDef<CombinedNode>[]>(
    () => [
      {
        accessorKey: 'node.identity',
        header: () => 'identity',
        cell: (info) => {
          const value = info.getValue<string>()
          return `0x...${value.slice(30, value.length - 1)}`
        },
      },
      {
        id: 'mode',
        header: 'mode',
        accessorKey: 'mode',
      },
      {
        accessorKey: 'connected',
        header: () => 'connected',
      },
      {
        accessorKey: 'node.containerName',
        header: () => 'name',
      },
      {
        accessorKey: 'node.balance',
        header: () => 'balance',
        cell: (info) => {
          const value = info.getValue<number>()
          return Math.trunc(value * 1000) / 1000
        },
      },
      {
        accessorKey: 'node.isCustom',
        header: () => 'custom',
      },
      {
        accessorKey: '',
        id: 'actions',
        size: 10,
        header: () => 'actions',
        cell: ({ row }) => {
          const data = row.original
          const isConsumer = data.mode === 'consumer'
          const isCustom = data.node.isCustom
          return (
            <div className="flex gap-2">
              {isConsumer && isCustom && (
                <FontAwesomeIcon
                  className="hover:scale-125"
                  icon={faPowerOff}
                  onClick={() => {
                    nodeRef.current = row.original
                    setShowConnectModal(true)
                  }}
                />
              )}
              {/*              {isConsumer && (
                <FontAwesomeIcon
                  className="hover:scale-125"
                  icon={faMoneyBills}
                  onClick={() => {
                    nodeRef.current = row.original
                    setShowTopupModal(true)
                  }}
                />
              )}*/}
              {isCustom && (
                <FontAwesomeIcon
                  className="hover:scale-125"
                  onClick={() => {
                    nodeRef.current = row.original
                    setShowConfirmDelete(true)
                  }}
                  icon={faTrashCan}
                />
              )}
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <>
      <Table columns={columns} data={nodes} />
      <ConfirmationModal
        show={showConfirmDelete}
        message={
          <div>
            You are about to delete <strong>{nodeRef.current?.mode}</strong> -{' '}
            <strong>{nodeRef.current?.node?.containerName}</strong>
          </div>
        }
        onConfirm={async () => {
          if (nodeRef.current) {
            try {
              await deleteNode({ ...nodeRef.current })
              setShowConfirmDelete(false)
              toasts.info(`Removed ${nodeRef.current?.node.containerName}`)
              navigate(0)
            } catch (e) {
              toasts.error(`Failed to delete node ${nodeRef.current?.node.containerName}`)
            }
          }
        }}
        onClose={() => setShowConfirmDelete(false)}
      />
      <TopUpModal
        show={showTopupModal}
        onClose={() => setShowTopupModal(false)}
        onConfirm={async (amount) => {
          const identity = nodeRef.current?.node.identity
          if (!identity) {
            toasts.error(`Can't top up node! :(`)
            return
          }
          await api.topUpConsumer(amount, identity)
          setShowTopupModal(false)
          toasts.info('Top up done!')
        }}
        message={`Top up ${nodeRef.current?.node.containerName}`}
      />
      <ConsumerConnectModal
        consumerName={nodeRef.current?.node.containerName}
        show={showConnectModal}
        onClose={() => setShowConnectModal(false)}
        onConfirm={async (info) => {
          try {
            await api.consumerConnect({
              provider_id: info.providerId,
              download_limit_mb: info.dlLimitMb,
              consumer_name: nodeRef.current?.node.containerName ?? '',
              duration_seconds: info.durationSeconds,
              wait: false,
            })
            toasts.info(`Hang tight, connection is coming!`)
          } catch (e) {
            toasts.error(`Couldn't send request for connection`)
          }
        }}
      />
    </>
  )
}
