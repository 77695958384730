import './index.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// use strict mode for dev environment for additional checks; this will cause components to be rendered twice resulting in double requests
// root.render(<React.StrictMode>{app}</React.StrictMode>)
root.render(app)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
