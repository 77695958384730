import { useId } from 'react'
import { Option } from '../../common/types'

type Props = {
  options?: Option[]
  label?: string
  onChange?: (v: string) => void
}

export const Dropdown = ({ label, options, onChange }: Props) => {
  const id = useId()

  const mapped = options?.map((it) => <option value={it.value}>{it.label}</option>)

  return (
    <div className="grid grid-cols-3 gap-2">
      <label htmlFor={id}>{label}</label>
      <select
        onChange={(e) => {
          const value = e.target.value
          onChange && onChange(value)
        }}
        className="col-span-2 border-2 border-teal-800 active:border-2 hover:border-teal-300 focus:border-teal-400"
        id={id}
      >
        {mapped}
      </select>
    </div>
  )
}
