type Props = {
  label: string
  onClick?: () => Promise<unknown> | unknown
  type?: 'button' | 'submit'
  active?: boolean
}

export const Button = ({ label, onClick, type = 'button', active }: Props) => {
  return (
    <input
      className={`${
        active && 'bg-teal-300'
      } cursor-pointer px-4 outline outline-1 outline-teal-500 rounded-sm p-1 hover:bg-teal-500 active:bg-teal-300 active:outline-teal-300`}
      type={type}
      value={label}
      onClick={onClick}
    />
  )
}
