import { Form } from '../components/input/Form'
import { TextInput } from '../components/input/TextInput'
import { useState } from 'react'
import { Button } from '../components/input/Button'
import { api } from '../api/api'
import { useNavigate } from 'react-router'
import { routes } from '../AppRouter'

export const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const login = async () => {
    try {
      await api.login({ username, password, pool: 'employees' })
      navigate(routes.HOME)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Form className="min-w-fit w-1/5 h-fit p-6 border-2 rounded border-teal-600 flex flex-col justify-center items-center gap-4">
        <TextInput label="username" value={username} onChange={setUsername} />
        <TextInput label="password" type="password" value={password} onChange={setPassword} />
        <Button label="Login" onClick={login} />
      </Form>
    </div>
  )
}
