import styled from 'styled-components'
import { ReactNode } from 'react'

const Container = styled.div`
  width: 80%;
  border: 1px solid mediumspringgreen;
  border-radius: 20px;

  overflow-x: auto;

  :hover {
    background-color: rgba(0, 250, 154, 0.3);
    cursor: pointer;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.7rem;

  margin: 20px;
`
export const Card = ({ children }: { children: ReactNode | ReactNode[] }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  flex-wrap: wrap;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 800;
  font-size: 16px;
  line-height: 36px;
  border-bottom: 1px dashed;
`
