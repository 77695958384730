import { ConsumerNode } from '../../api/types'
import { Info } from './Info'
import { Card, Header, InfoContainer } from './Common'

interface Props {
  consumer: ConsumerNode
}

export const ConsumerCard = ({ consumer: { node, proxyPort, connected } }: Props) => {
  return (
    <Card>
      <Header>
        <div>{node.identity}</div>
        <div>Consumer</div>
      </Header>

      <InfoContainer>
        <Info name="Version:" value={node.mystVersion} />
        <Info name="Proxy Port:" value={proxyPort} />
        <Info name="Channel:" value={node.channelAddress} />
        <Info name="Reg. Status:" value={node.registrationStatus} />
        <Info name="Reg. Method:" value={node.registrationMethod} />
        <Info name="Ready:" value={node.ready ? 'true' : 'false'} />
        <Info name="Connected:" value={connected ? 'true' : 'false'} />
        <Info name="Balance:" value={node.balance} />
      </InfoContainer>
    </Card>
  )
}
