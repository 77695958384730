import { Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/LoginPage'
import { TestPrintPage } from './pages/TestPrintPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { HomePage } from './pages/HomePage'

export const routes = Object.freeze({
  HOME: '/',
  LOGIN: '/login',
  TEST_PRINT: '/test-print',
})

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={routes.HOME} element={<HomePage />} />
      <Route path={routes.LOGIN} element={<LoginPage />} />
      <Route path={routes.TEST_PRINT} element={<TestPrintPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
