const find = <T extends object>(key: string): T | null => {
  const found = localStorage.getItem(key)

  if (found) {
    return JSON.parse(found) as T
  }

  return null
}

const save = (key: string, thing: object) => localStorage.setItem(key, JSON.stringify(thing))
const remove = (key: string) => localStorage.removeItem(key)

export const localStore = {
  TOKENS: 'tokens',
  find,
  save,
  remove,
}
