import { ReactNode, useState } from 'react'
import { Modal } from '../Modal'
import { Form } from '../input/Form'
import { TextInput } from '../input/TextInput'
import { Button } from '../input/Button'

type Props = {
  show?: boolean
  message?: ReactNode
  onClose?: () => void
  onConfirm?: (info: State) => void
  consumerName?: string
}

type State = {
  dlLimitMb: number
  durationSeconds: number
  providerId: string
}

const STATE_DEFAULT: State = {
  dlLimitMb: 50,
  durationSeconds: 20,
  providerId: '',
}

export const ConsumerConnectModal = ({ show, onClose, onConfirm, consumerName }: Props) => {
  const [state, setState] = useState<State>(STATE_DEFAULT)
  const [loading, setLoading] = useState(false)

  const setProviderId = (v: string) => setState((p) => ({ ...p, providerId: v }))
  const setDlLimitMb = (v: string) => setState((p) => ({ ...p, dlLimitMb: Number(v) }))
  const setDlUrl = (v: string) => setState((p) => ({ ...p, dlUrl: v }))
  const setDurationSeconds = (v: string) => setState((p) => ({ ...p, durationSeconds: Number(v) }))

  const handleSubmit = async () => {
    setLoading(true)
    try {
      onConfirm && onConfirm({ ...state })
      onClose && onClose()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onClose={onClose} title="Test Connection" loading={loading}>
      <Form className="p-4 flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput label="Provider ID" value={state.providerId} onChange={setProviderId} />
        <TextInput label="Consumer Name" disabled value={consumerName} />
        <TextInput label="D/L limit MB" type="number" value={state.dlLimitMb} onChange={setDlLimitMb} />
        <TextInput label="Duration" type="number" value={state.durationSeconds} onChange={setDurationSeconds} />
        <div className="flex w-full justify-center gap-4">
          <Button label="Ok" type="submit" />
          <Button label="Cancel" onClick={onClose} />
          <Button label="Reset" onClick={() => setState(STATE_DEFAULT)} />
        </div>
      </Form>
    </Modal>
  )
}
