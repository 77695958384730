import { routes } from '../../AppRouter'
import { NavLink } from 'react-router-dom'

export const Navbar = () => {
  return (
    <div className="w-full flex gap-4 p-2 border-b-2 border-teal-600">
      <NavItem path={routes.HOME} label="Home" />
      <NavItem path={routes.TEST_PRINT} label="Listings" />
    </div>
  )
}

type NavItemProps = {
  label?: string
  path: string
}

const NAV_CLASS =
  'outline outline-1 p-1 px-4 rounded hover:bg-teal-300 outline-teal-600 active:bg-teal-200 active:outline-teal-200'
const NAV_CLASS_ACTIVE = `${NAV_CLASS} bg-teal-200`

const NavItem = ({ path, label }: NavItemProps) => (
  <NavLink className={({ isActive }) => (isActive ? NAV_CLASS_ACTIVE : NAV_CLASS)} to={path}>
    {label}
  </NavLink>
)
