import { Modal } from './Modal'
import { Button } from './input/Button'
import { ReactNode, useState } from 'react'

type Props = {
  show?: boolean
  message?: ReactNode
  onClose?: () => void
  onConfirm?: () => void | Promise<void>
}

export const ConfirmationModal = ({ show, onConfirm, onClose, message }: Props) => {
  const [loading, setLoading] = useState(false)
  return (
    <Modal show={show} onClose={onClose} loading={loading}>
      <div className="w-fit flex flex-col p-4 gap-6">
        <h3 className="text-2xl w-full text-center">Confirmation</h3>
        <div>{message}</div>
        <div className="flex w-full justify-center gap-2">
          <Button
            label="Ok"
            onClick={async () => {
              setLoading(true)
              try {
                onConfirm && (await onConfirm())
              } finally {
                setLoading(false)
              }
            }}
          />
          <Button label="Canel" onClick={() => onClose && onClose()} />
        </div>
      </div>
    </Modal>
  )
}
