import { ProviderNode } from '../../api/types'
import { Info } from './Info'
import { Card, Header, InfoContainer } from './Common'

interface Props {
  provider: ProviderNode
}

export const ProviderCard = ({ provider: { node, serviceStarted, proposalInDiscovery } }: Props) => {
  return (
    <Card>
      <Header>
        <div>{node.identity}</div>
        <div>Provider</div>
      </Header>
      <InfoContainer>
        <Info name="Version:" value={node.mystVersion} />
        <Info name="Channel:" value={node.channelAddress} />
        <Info name="Reg. Status:" value={node.registrationStatus} />
        <Info name="Reg. Method:" value={node.registrationMethod} />
        <Info name="Ready:" value={node.ready ? 'true' : 'false'} />
        <Info name="Service ON:" value={serviceStarted ? 'true' : 'false'} />
        <Info name="In discovery:" value={proposalInDiscovery ? 'true' : 'false'} />
      </InfoContainer>
    </Card>
  )
}
