import styled from 'styled-components'

interface Props {
  name: string
  value: any
}

const Name = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin-right: 6px;
  width: 5rem;
  text-align: right;
`

const Value = styled.div`
  font-weight: 600;
  font-size: 12px;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Info = ({ name, value }: Props) => (
  <Container>
    <Name>{name}</Name>
    <Value>{value}</Value>
  </Container>
)
