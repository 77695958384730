import React, { useEffect } from 'react'
import { localStore } from './common/localStore'
import { api, SentinelAuthResponse } from './api/api'
import { useNavigate } from 'react-router'
import { AppRouter, routes } from './AppRouter'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const found = localStore.find<SentinelAuthResponse>(localStore.TOKENS)
      if (!found) {
        navigate(routes.LOGIN)
        return
      }

      try {
        await api.validate(found.authToken)
      } catch (e: unknown) {
        localStore.remove(localStore.TOKENS)
        navigate(routes.LOGIN)
      }
    })()
  }, [])
  return (
    <>
      <AppRouter />
      <ToastContainer />
    </>
  )
}

export default App
