import { Button } from '../input/Button'
import { useState } from 'react'
import { toasts } from '../../common/toast'
import { Modal } from '../Modal'
import { Form } from '../input/Form'
import { TextInput } from '../input/TextInput'
import { Dropdown } from '../input/Dropdown'
import { Option } from '../../common/types'

type Props = {
  title: string
  message?: string
  onClick?: (info: State) => void | Promise<void>
  registrationOptions?: Option[]
}

type State = {
  name: string
  mystVersion: string
  dockerRepo: string
  registrationMethod: string
}

// json:"registration_method" enum:"offchain,manual,with_order"
const DEFAULT_STATE: State = {
  name: 'My_Node',
  mystVersion: '1.20.4-alpine',
  dockerRepo: 'mysteriumnetwork/myst',
  registrationMethod: 'manual',
}

export const AddNode = ({ title, onClick, message, registrationOptions }: Props) => {
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const [showConfirm, setShowConfirm] = useState(false)
  const [loading, setLoading] = useState(false)

  const setName = (v: string) => setState((p) => ({ ...p, name: v }))
  const setVersion = (v: string) => setState((p) => ({ ...p, mystVersion: v }))
  const setDockerRepo = (v: string) => setState((p) => ({ ...p, mystVersion: v }))
  const setRegistration = (v: string) => setState((p) => ({ ...p, registrationMethod: v }))

  const onSubmit = async () => {
    try {
      setLoading(true)
      onClick && (await onClick(state))
      setShowConfirm(false)
      toasts.info(`Success: ${message}`)
    } catch (err) {
      toasts.error(`Failed to: ${message}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        label={title}
        type="button"
        onClick={() => {
          setShowConfirm(true)
        }}
      />
      <Modal show={showConfirm} onClose={() => setShowConfirm(false)} loading={loading}>
        <Form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
          <h3 className="text-2xl w-full text-center">{message}</h3>
          <TextInput label="Name" value={state.name} onChange={setName} />
          <TextInput label="Version" value={state.mystVersion} onChange={setVersion} />
          <div className="w-full">
            <a
              className="text-blue-500 "
              href="https://hub.docker.com/repository/docker/mysteriumnetwork/myst/tags?page=1&ordering=last_updated"
              target="_blank"
            >
              available mysteriumnetwork/myst tags
            </a>
          </div>
          <TextInput label="Docker repo" value={state.dockerRepo} onChange={setDockerRepo} />
          <Dropdown label="Registration" onChange={setRegistration} options={registrationOptions} />
          <div className="flex w-full justify-center gap-4">
            <Button label="Ok" type="submit" />
            <Button label="Canel" onClick={() => setShowConfirm(false)} />
          </div>
        </Form>
      </Modal>
    </>
  )
}
