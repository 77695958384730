import { WithChildren } from '../types'
import classNames from 'classnames'

type Props = {
  show?: boolean
  onClose?: () => void
  title?: string
  className?: string
  loading?: boolean
} & WithChildren

export const Modal = ({ onClose, show, children, title, className, loading }: Props) => {
  if (!show) {
    return null
  }
  return (
    <>
      <div
        className="fixed w-screen h-screen bg-teal-50 top-0 left-0 bg-opacity-50"
        onClick={() => !loading && onClose && onClose()}
      />
      <div
        className={classNames(
          'fixed rounded left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 outline outline-teal-500 outline-1 bg-white',
          className,
        )}
      >
        <div className="w-full h-full relative">
          {loading && (
            <div className="absolute flex flex-col justify-center items-center w-full h-full">
              <h3 className="absolute text-4xl font-bold z-20 text-center">Working... 🍩</h3>
              <div className="absolute w-full h-full z-10 bg-gray-400 opacity-80"></div>
            </div>
          )}
          {title && <h2 className="text-center text-2xl font-bold">{title}</h2>}
          {children}
        </div>
      </div>
    </>
  )
}
