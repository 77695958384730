import axios, { AxiosRequestConfig } from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import { NodesResponse } from './types'
import { localStore } from '../common/localStore'

const CONVERT_OPTIONS = Object.freeze({
  deep: true,
})

const buildHttp = (config?: AxiosRequestConfig) => {
  const http = axios.create(config)

  http.interceptors.request.use((config) => {
    if (config?.params) {
      config.params = snakecaseKeys(config.params, CONVERT_OPTIONS)
    }
    if (config?.data) {
      config.data = snakecaseKeys(config.data, CONVERT_OPTIONS)
    }

    return config
  })

  http.interceptors.response.use((config) => {
    if (config?.data) {
      config.data = camelcaseKeys(config.data, CONVERT_OPTIONS)
    }
    return config
  })

  return http
}

type Credentials = {
  username: string
  password: string
  pool: string
}

export type SentinelAuthResponse = {
  authToken: string
  refreshToken: string
}

export const botFatherApi = () => {
  // const http = buildHttp({ baseURL: 'http://localhost:3000' })
  const http = buildHttp({
    baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
  })

  http.interceptors.request.use((config) => {
    const auth = localStore.find<SentinelAuthResponse>(localStore.TOKENS)

    if (auth) {
      config.headers
        ? (config.headers['Authorization'] = `Bearer ${auth.authToken}`)
        : (config.headers = { Authorization: `Bearer ${auth.authToken}` })
    }

    return config
  })

  return {
    login: async (credentials: Credentials): Promise<SentinelAuthResponse> => {
      const tokens = await http
        .post<SentinelAuthResponse>('/api/v1/sentinel/auth/password', credentials)
        .then((r) => r.data)
      localStore.save(localStore.TOKENS, tokens)
      return tokens
    },

    validate: async (token: string): Promise<void> => {
      await http.post(`/api/v1/sentinel/validate`, { token })
    },

    listNodes: async (): Promise<NodesResponse> => {
      return await http.get<NodesResponse>('/api/v1/nodes').then((r) => r.data)
    },

    deleteProvider: async (name: string): Promise<void> => {
      return http.delete('/api/v1/nodes/provider', { data: { name } })
    },

    deleteConsumer: async (name: string): Promise<void> => {
      return http.delete('/api/v1/nodes/consumer', { data: { name } })
    },

    topUpConsumer: async (amount: number, identity: string): Promise<void> => {
      return http.post('/api/v1/nodes/consumer/topup', { amount, identity })
    },

    createConsumer: async (req: CreateNodeRequest): Promise<void> => {
      return http.post('/api/v1/nodes/consumer', req)
    },

    createProvider: async (req: CreateNodeRequest): Promise<void> => {
      return http.post('/api/v1/nodes/provider', req)
    },

    consumerConnect: async (req: ConsumerConnectRequest): Promise<void> => {
      return http.post('/api/v1/nodes/consumer/connect', req)
    },
  }
}

type ConsumerConnectRequest = {
  consumer_name: string
  download_limit_mb: number
  duration_seconds: number
  provider_id: string
  wait: boolean
}

type CreateNodeRequest = {
  name: string
  myst_version: string
  docker_repo: string
  registration_method: string
}

export const api = botFatherApi()
