import { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
`

interface Props<T> {
  items: T[]
  mapper: (item: T) => ReactNode
}

export const List = <T extends unknown>({ items, mapper }: Props<T>) => {
  const mapped = items.map(mapper)
  return <Container>{mapped}</Container>
}
