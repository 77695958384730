import { Layout } from '../components/Layout'
import { Nodes } from '../components/nodes/Nodes'

export const HomePage = () => {
  return (
    <Layout navbar>
      <Nodes />
    </Layout>
  )
}
