import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { api } from '../api/api'
import { ConsumerNode, NodesResponse, ProviderNode } from '../api/types'
import { List } from '../components/List'
import { ConsumerCard } from '../components/Cards/ConsumerCard'
import { ProviderCard } from '../components/Cards/ProviderCard'
import image from './botfather.png'
import { Layout } from '../components/Layout'

const Container = styled.div`
  position: relative;
  background-color: black;
  color: white;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  :*  {
    border-right: 1px dotted blue;
  }
`

const StyledImager = styled.img`
  position: fixed;
  opacity: 0.15;
  z-index: -1;
  right: calc(50% - 315px);
`

export const TestPrintPage = () => {
  const [nodes, setNodes] = useState<NodesResponse>({ consumers: [], providers: [] })

  useEffect(() => {
    api.listNodes().then(setNodes)
    const interval = setInterval(() => {
      api.listNodes().then(setNodes)
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Layout navbar title="Listings">
      <Container>
        <StyledImager src={image} />
        <Row>
          <List<ConsumerNode> items={nodes.consumers} mapper={(consumer) => <ConsumerCard consumer={consumer} />} />
          <List<ProviderNode> items={nodes.providers} mapper={(provider) => <ProviderCard provider={provider} />} />
        </Row>
      </Container>
    </Layout>
  )
}
