import { useId } from 'react'

type Props = {
  label?: string
  value?: string | number
  onChange?: (v: string) => void
  type?: 'email' | 'password' | 'text' | 'number' | string
  disabled?: boolean
}

export const TextInput = ({ label, value, onChange, type = 'text', disabled }: Props) => {
  const id = useId()
  return (
    <div className="grid grid-cols-3 gap-2">
      <label htmlFor={id}>{label}</label>
      <input
        value={value}
        disabled={disabled}
        onChange={(e) => {
          const { value } = e.target
          onChange && onChange(String(value))
        }}
        type={type}
        className="col-span-2 border-2 border-teal-800 active:border-2 hover:border-teal-300 focus:border-teal-400"
        id={id}
      />
    </div>
  )
}
