import { DependencyList, useEffect, useState } from 'react'

export const useFetch = <T = unknown>(fetch: () => Promise<T>, deps: DependencyList = []) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()
  const [data, setData] = useState<T>()

  useEffect(() => {
    refetch()
  }, deps)

  const refetch = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await fetch()
      setData(response)
    } catch (err: any) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return { data, loading, error, refetch }
}
