import { WithChildren } from '../types'
import { Navbar } from './nav/Navbar'

type Props = {
  navbar?: boolean
  title?: string
} & WithChildren

export const Layout = ({ navbar, children, title }: Props) => {
  return (
    <div className="w-full h-full">
      {navbar && <Navbar />}
      {title && (
        <div className="flex flex-col items-center">
          <h1 className="text-center text-6xl my-4 w-full font-extrabold tracking-widest">{title}</h1>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}
