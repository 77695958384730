import { api } from '../../api/api'
import { useFetch } from '../../common/hooks'
import React from 'react'
import { NodesTable } from './NodesTable'
import { CombinedNode } from '../../api/types'
import { ConfirmationModal } from '../ConfirmationModal'
import { AddNode } from './AddNode'
import { useNavigate } from 'react-router'

export const Nodes = () => {
  const navigate = useNavigate()
  const { data = { consumers: [], providers: [] } } = useFetch(() => api.listNodes())
  const { consumers, providers } = data

  const combo: CombinedNode[] = [
    ...consumers.map<CombinedNode>((c) => ({ ...c, mode: 'consumer' })),
    ...providers.map<CombinedNode>((p) => ({ ...p, mode: 'provider' })),
  ]

  return (
    <div className="w-full flex flex-col items-center gap-4">
      <div className="flex gap-4 mt-4">
        <AddNode
          title="+ Provider"
          message="Create provider?"
          onClick={async (info) => {
            await api.createProvider({
              name: info.name,
              myst_version: info.mystVersion,
              registration_method: info.registrationMethod,
              docker_repo: info.dockerRepo,
            })
            navigate(0)
          }}
          registrationOptions={[
            { value: 'manual', label: 'manual' },
            { value: 'with_order', label: 'with_order' },
          ]}
        />
        <AddNode
          title="+ Consumer"
          message="Create consumer?"
          onClick={async (info) => {
            await api.createConsumer({
              name: info.name,
              myst_version: info.mystVersion,
              registration_method: info.registrationMethod,
              docker_repo: info.dockerRepo,
            })
            navigate(0)
          }}
          registrationOptions={[
            { value: 'manual', label: 'manual' },
            { value: 'offchain', label: 'offchain' },
          ]}
        />
      </div>
      <div className="w-11/12 flex flex-col items-center">
        <NodesTable nodes={combo} />
      </div>
      <ConfirmationModal />
    </div>
  )
}
