import { Layout } from '../components/Layout'
import image from '../assets/botfather.png'

export const NotFoundPage = () => {
  return (
    <Layout navbar>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-center text-9xl">404</h1>
        <h2 className="text-center text-4xl">Compare your lives to mine and then kill your selves!</h2>
        <img className="w-1/3 rounded mt-6 opacity-90" alt="bender" src={image} />
      </div>
    </Layout>
  )
}
