import { ReactNode, useState } from 'react'
import { Modal } from '../Modal'
import { Form } from '../input/Form'
import { TextInput } from '../input/TextInput'
import { Button } from '../input/Button'

type Props = {
  show?: boolean
  message?: ReactNode
  onClose?: () => void
  onConfirm?: (amountMyst: number) => void
}

export const TopUpModal = ({ show, onConfirm, onClose, message }: Props) => {
  const [amount, setAmount] = useState(1)
  return (
    <Modal show={show} onClose={onClose} className="p-4" title="asdsad">
      <Form
        className="flex flex-col gap-6"
        onSubmit={async () => {
          onConfirm && (await onConfirm(amount))
          setAmount(1)
        }}
      >
        <p className="text-center font-bold">{message}</p>
        <TextInput
          type="number"
          label="Amount MYST"
          value={amount}
          onChange={(e) => {
            setAmount(Number(e))
          }}
        />
        <div className="flex gap-2 w-full justify-center">
          <Button label="Ok" type="submit" />
          <Button label="Cancel" onClick={onClose} />
        </div>
      </Form>
    </Modal>
  )
}
