import { ReactNode } from 'react'

type Props = {
  onSubmit?: () => Promise<void> | void
  children?: ReactNode
  className?: string
}

export const Form = ({ children, onSubmit, className }: Props) => {
  return (
    <form
      className={className}
      onSubmit={async (e) => {
        e.preventDefault()
        onSubmit && (await onSubmit())
      }}
    >
      {children}
    </form>
  )
}
